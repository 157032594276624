<template>
		<v-card class="pa-2">
			<!-- {{evento}} -->
			<v-container>
				<v-row>
					<v-col cols="12">
							<v-chip :color="evento.colorTipoEvento" large label outlined class="v-chip--active">
								<v-icon left>{{ evento.iconTipoEvento }}</v-icon>
								{{evento.tipoEvento}}
							</v-chip>
							<v-chip :color="evento.colorEstadoEvento" large label outlined class="v-chip--active ml-2">
								<v-icon left>{{ evento.iconEstadoEvento }}</v-icon>
								{{evento.estado}}
							</v-chip>
					</v-col>
					<v-col cols="12" md="4">
						<tarjeta-datos titulo="Detalles evento" icon="calendar-edit" :datos="[
							{nombre: 'Fecha inicio', valor: parseDate(evento.fechaInicioEvento)},
							{nombre: 'Horario', valor: (evento.horario)},
							...[
								{nombre: 'Novios', valor: (evento.novios)},
								{nombre: 'Enlace civil', valor: (evento.enlaceCivil) ? 'Sí' : 'No'},
								{nombre: 'Tipo boda', valor: (evento.tipoBoda)},
							].filter(() => evento.tipoEvento == 'Boda'),
							...[
								{nombre: 'Niño', valor: (evento.nino)},
								{nombre: 'Padres', valor: (evento.padres)},
							].filter(() => evento.tipoEvento == 'Comunión')
						]" />
					</v-col>
					<v-col cols="12" md="4">
						<tarjeta-datos titulo="Detalles lugar" icon="map-marker" :datos="[
							{nombre: 'Espacio', valor: (evento.nombreEspacio)},
							{nombre: 'Salón', valor: (evento.nombreSalon)},
							
							{nombre: 'Nº Adultos', valor: (evento.nAdultos)},
							{nombre: 'Nº Niños', valor: (evento.nNinos)},
							{nombre: 'Tcos.', valor: (evento.tcos)},
						]" />
					</v-col>
				</v-row>
			</v-container>
		</v-card>
</template>

<script>
import {parseDate} from '@/utils/index.js'

export default {
	components: {
		TarjetaDatos: () => import("@/components/TarjetaDatos.vue")
	},
	props: {
		idEvento : {type: Number, opcional: false}
	},
  data() {
    return {
			evento: {},
		};
  },
  methods: {
		parseDate,
		async getEvento() {
			if (!this.idEvento && !this.$route.params.id) return;
			const {data} = await axios({
				url: `/eventos/${this.idEvento || this.$route.params.id}`
			})

			this.evento = data
		}
	},
	mounted() {
		this.getEvento();
	}
};
</script>
